import { Profile } from "./profile"
import { SocialMediaBar } from "./socialMediaBar"
import { GithubGrid } from "./githubgrid"

export const Body = () => {
    return (
        <div className="main-body">
            <div className="main-body main-body__header ">
                <Profile/>
                <SocialMediaBar />
                <GithubGrid />
            </div>
        </div>
    )
}