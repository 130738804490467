export const Profile = () =>{
    return (
        <div className = "profile ">
            <img 
                src = "https://cdn.discordapp.com/attachments/1072475409876267020/1072635508615815258/IMG_0011.jpg" 
                id="profile-image"
                alt="profile image"
                className="outline outline-gray-700 outline-[0.5px]"
            />

            <div className = "flex flex-row gap-[90px] text-white">
                <h3>Andrey Neudachin</h3>
                <h3>Fullstack Developer</h3>
            </div>
            
            <div className = "w-[110%] bg-white h-1"/>
        </div>
    )
}
