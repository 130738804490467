import { useEffect, useState } from 'react';


export const GithubGrid = () => { 
    var [repos, setRepos] = useState([]);
    useEffect(() => {
        fetch("https://api.github.com/users/miromiro11/repos")
            .then(res => res.json())
            .then(
                (result) => {
                    setRepos(result);
                },
                (error) => {
                    console.log(error);
                }
            )
    }, [])
    return (
        <div className = "github-grid grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
            {repos.map(
                (repo) => !repo.fork && githubTile(repo)
            )}
        </div>
    )
}

function githubTile({name, description, url}) { 
    return (
        <div key={url} className="github-tile flex flex-col items-center gap-10 w-64 h-[240px] bg-gray-200/20 rounded-xl text-white" href={url}>
            <div className="github-tile-header overflow-hidden">
                <h4>{name.toUpperCase()}</h4>
                <div className="breakLine h-1 w-full bg-gray-200/20"></div>
                {
                    <div className="h-[100%] p-2 overflow-scroll scrollbar-thin">
                        <p>{description}</p>
                    </div>

                }
            </div>
        </div>
    )
}