export const SocialMediaBar = () => {
    return (
        <div className= "social-media-bar flex flex-wrap justify-center align-center items-center m-0 p-0">
            <ul>
                <li>
                    <a href = "https://github.com/miromiro11">
                        <img alt = "github" src = "https://raw.githubusercontent.com/gauravghongde/social-icons/9d939e1c5b7ea4a24ac39c3e4631970c0aa1b920/SVG/White/Github_white.svg"></img>
                    </a>
                </li>
                <li>
                    <a href = "https://www.linkedin.com/in/andrey-neudachin-18b6481ab/">
                        <img alt = "LinkedIn" src = "https://raw.githubusercontent.com/gauravghongde/social-icons/9d939e1c5b7ea4a24ac39c3e4631970c0aa1b920/SVG/White/LinkedIN_white.svg"></img>
                    </a>
                </li>

                <li>
                    <a href = "https://www.instagram.com/notmiromiro/">
                        <img alt = "Instagram" src = "https://raw.githubusercontent.com/gauravghongde/social-icons/9d939e1c5b7ea4a24ac39c3e4631970c0aa1b920/SVG/White/Instagram_white.svg"></img>
                    </a>
                </li>
                <li>
                    <a href = "https://www.facebook.com/miromiro.dev/">
                        <img alt = "Facebook" src = "https://raw.githubusercontent.com/gauravghongde/social-icons/9d939e1c5b7ea4a24ac39c3e4631970c0aa1b920/SVG/White/Facebook_white.svg"></img>
                    </a>
                </li>
            </ul>
        </div>
    )
}